import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CredentialsComponent } from './credentials/credentials.component';
import { DeploymentsComponent } from './deployments/deployments.component';
import { ActivityComponent } from './activity/activity.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { DeployComponent } from './deploy/deploy.component';

const routes: Routes = [
  { path: '', redirectTo: 'deployments', pathMatch: 'full' },
  {
    path: 'login',
    component: CredentialsComponent,
  },
  {
    path: 'deployments',
    component: DeploymentsComponent,
  },
  {
    path: 'activity',
    component: ActivityComponent,
  },
  {
    path: 'analytics',
    component: AnalyticsComponent,
  },
  {
    path: 'deploy/:slug',
    component: DeployComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      bindToComponentInputs: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

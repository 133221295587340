import { Component, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { interval, startWith } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { HeaderComponent } from '../header/header.component';

@Pipe({ name: 'snapshotPipe', standalone: true })
export class SnapshotPipe implements PipeTransform {
  transform(snapshot: any) {
    if (snapshot === null) {
      return '';
    }

    const lines = [
      { arr: snapshot, label: 'total' },
      {
        arr: snapshot.filter(
          (t: any) => t.eval_id === null && t.status === 'dormant'
        ),
        label: 'available',
      },
      {
        arr: snapshot.filter((t: any) => t.status === 'dormant'),
        label: 'dormant',
      },
    ];

    return lines.map((x) => `${x.arr.length} ${x.label}`).join('; ');
  }
}

@Component({
  selector: 'app-evaluations',
  imports: [CommonModule, HeaderComponent, SnapshotPipe],
  templateUrl: './evaluations.component.html',
  styleUrls: ['./evaluations.component.scss'],
})
export class EvaluationsComponent implements OnInit {
  private http = inject(HttpClient);

  evaluations: any[] = [];

  refreshQueues = interval(15000).pipe(takeUntilDestroyed(), startWith(-1));

  ngOnInit(): void {
    this.refreshQueues.subscribe(() => {
      this.refreshEvaluationList();
    });
  }

  refreshEvaluationList() {
    this.http.get('/api/evaluations').subscribe(
      (data: any) => {
        this.evaluations = data['data'] as any[];
      },
      (error) => {
        console.error(error);
      }
    );
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'suppress0',
  standalone: true,
})
export class Suppress0Pipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    if (Number(value) == 0) {
      return '';
    }
    return value;
  }
}

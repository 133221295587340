import { Component, Input, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { HeaderComponent } from '../header/header.component';

@Component({
  selector: 'app-deploy',
  standalone: true,
  imports: [CommonModule, HeaderComponent],
  templateUrl: './deploy.component.html',
  styleUrls: ['./deploy.component.scss'],
})
export class DeployComponent implements OnInit {
  @Input() slug?: string;
  private http = inject(HttpClient);

  dataSummary: any;
  envSummary: any;
  versionSummary: any;

  containerName?: string | null;
  containerBareLogs: any;
  containerLogs: any;

  ngOnInit(): void {
    this.http.get(`/api/deployment/${this.slug}/data-stats`).subscribe(
      (data) => {
        this.dataSummary = data as any[];
      },
      (error) => {
        console.error(error);
      }
    );
    this.http.get(`/api/deployment/${this.slug}/env-stats`).subscribe(
      (data) => {
        this.envSummary = data as any[];
      },
      (error) => {
        console.error(error);
      }
    );
    this.http.get(`/api/deployment/${this.slug}/version-stats`).subscribe(
      (data) => {
        this.versionSummary = data as any[];
      },
      (error) => {
        console.error(error);
      }
    );
  }

  onGetLogs(name: string) {
    this.http
      .get(`/api/deployment/${this.slug}/docker-logs`, {
        params: { container: name },
      })
      .subscribe(
        (data: any) => {
          this.containerName = name;
          this.containerLogs = data;
          this.containerBareLogs = data['lines']
            .map((r: any) => r.text)
            .join('\n');
        },
        (error) => {
          console.error(error);
        }
      );
  }

  onClearLogs() {
    this.containerName = null;
    this.containerLogs = null;
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'environmentSummary', standalone: true })
export class EnvironmentSummaryPipe implements PipeTransform {
  transform(data: any) {
    if (data === undefined) {
      return 'unknown';
    }
    const running = data['containers'].filter(
      (c: any) => c['State'] === 'running'
    ).length;
    const unrunning = data['containers'].filter(
      (c: any) => c['State'] !== 'running'
    ).length;
    const gbfree = (data['disk']['free'] / (1024 * 1024 * 1024)).toFixed(1);

    return [
      `${running} running`,
      `${unrunning} stopped`,
      `${gbfree} GB available`,
    ].join('; ');
  }
}

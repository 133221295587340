import { Component, OnInit, inject, DestroyRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { HttpClient } from '@angular/common/http';
import { take, catchError, EMPTY, tap } from 'rxjs';
import {
  Validators,
  ReactiveFormsModule,
  NonNullableFormBuilder,
} from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';

@Component({
  selector: 'app-credentials',
  standalone: true,
  imports: [
    CommonModule,
    MatInputModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './credentials.component.html',
  styleUrls: ['./credentials.component.scss'],
})
export class CredentialsComponent implements OnInit {
  private http = inject(HttpClient);
  private formBuilder = inject(NonNullableFormBuilder);
  private destroyRef = inject(DestroyRef);
  private router = inject(Router);

  loginError = '';

  loginForm = this.formBuilder.group({
    username: '',
    password: ['', Validators.required],
  });

  isLoading = false;

  get passwordCtrl() {
    return this.loginForm.controls.password;
  }

  get usernameCtrl() {
    return this.loginForm.controls.username;
  }

  ngOnInit(): void {
    this.handleValidators();

    this.loginForm.valueChanges
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(() => {
          if (this.loginError) {
            this.loginError = '';
          }
        })
      )
      .subscribe();
  }

  handleSubmit() {
    return this.login();
  }

  private handleValidators() {
    this.usernameCtrl.setValidators(Validators.required);
  }

  private login() {
    this.isLoading = true;
    const body = this.loginForm.value;

    this.http
      .post('api/session', body)
      .pipe(
        take(1),
        tap(() => {
          this.isLoading = false;
          this.router.navigate(['/']);
        }),
        catchError((err) => {
          this.isLoading = false;
          this.loginError = 'Unauthorized';
          return EMPTY;
        })
      )
      .subscribe();
  }
}

import { Component, Inject, inject } from '@angular/core';

import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogModule,
} from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'app-invite-tool',
  imports: [
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
  ],
  templateUrl: './invite-tool.component.html',
  styleUrls: ['./invite-tool.component.scss'],
})
export class InviteToolComponent {
  private http = inject(HttpClient);

  constructor(
    public dialogRef: MatDialogRef<InviteToolComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(data);
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onInviteClick(): void {
    const invite: any = { address: this.data.email_addr };
    const body = JSON.stringify(invite);
    this.http
      .post(`/api/deployment/${this.data.target.org_slug}/send-invite`, body)
      .subscribe(
        (data) => {
          this.dialogRef.close({ queued: true });
        },
        (error) => {
          console.error(error);
        }
      );
  }
}

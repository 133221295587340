<app-header />

<div class="m-2 flex flex-col gap-4">
  @if (showDetails !== 'none') {
    <div class="details">
      <h1>Details</h1>
      <button (click)="onClearDetails()">Clear</button>
      @if (showDetails === 'actlist') {
        <div>
          @if (activityDetails | async; as actDet) {
            <table>
              <thead>
                <tr>
                  <th scope="col">Activity</th>
                  <th scope="col">Status</th>
                  <th scope="col">Time (UTC)</th>
                  <th scope="col">Duration (seconds)</th>
                  <th scope="col">Arguments</th>
                </tr>
              </thead>
              <tbody>
                @for (activity of actDet; track activity) {
                  <tr>
                    <td>{{ activity.act_name }}</td>
                    <td>{{ activity.status_code }}</td>
                    <td>
                      {{ activity.request_timestamp }}
                      <button (click)="onLogs(activity)">Web Logs</button>
                    </td>
                    <td class="ecount">
                      {{ activity.duration_seconds.toFixed(3) }}
                    </td>
                    <td>{{ JSON.stringify(activity.args) }}</td>
                  </tr>
                }
              </tbody>
            </table>
          }
        </div>
      }
      @if (showDetails === 'logs') {
        <div>
          @if (activityLogs | async; as actLog) {
            <pre class="boxed">{{ actLog }}</pre>
          }
        </div>
      }
    </div>
  }

  <h1>Error Summary</h1>

  <p>
    Important interesting diagnostics are available in the
    <a
      href="https://console.cloud.google.com/logs/query;query=resource.type%3D%22cloudsql_database%22%0Aresource.labels.database_id%3D%22precise-builder:prod-shared-02%22%0Alog_name%3D%22projects%2Fprecise-builder%2Flogs%2Fcloudsql.googleapis.com%252Fpostgres.log%22;duration=PT1H?project=precise-builder"
      >SQL server logs</a
    >. Not every SQL error is a problem specifically since it is a validation of
    last resort especially as it relates to uniqueness.
  </p>

  @if (activitySummary | async; as actSumm) {
    <table>
      <thead>
        <tr>
          <th scope="col" colspan="3"></th>
          <th scope="col" colspan="3">4xx and 5xx errors</th>
          <th scope="col" colspan="3">5xx errors</th>
        </tr>
      </thead>
      <thead>
        <tr>
          <th scope="col">Slug</th>
          <th scope="col">Company</th>
          <th scope="col">Activity</th>
          <th scope="col">24 Hour</th>
          <th scope="col">3 Hour</th>
          <th scope="col">15 Minute</th>
          <th scope="col">24 Hour</th>
          <th scope="col">3 Hour</th>
          <th scope="col">15 Minute</th>
        </tr>
      </thead>
      <tbody>
        @for (activity of actSumm; track activity) {
          <tr>
            <td>{{ activity.org_slug }}</td>
            <td>{{ activity.company_name }}</td>
            <td>{{ activity.act_name }}</td>
            <td class="ecount">
              {{ activity.count_24hour | suppress0 }}
              <span (click)="onDetails(activity, 'all', '24hour')"
                >&#x21E7;</span
              >
            </td>
            <td class="ecount">
              {{ activity.count_3hour | suppress0 }}
              <span (click)="onDetails(activity, 'all', '3hour')"
                >&#x21E7;</span
              >
            </td>
            <td class="ecount">
              {{ activity.count_15minute | suppress0 }}
              <span (click)="onDetails(activity, 'all', '15minute')"
                >&#x21E7;</span
              >
            </td>
            <td class="ecount">
              {{ activity.count_500_24hour | suppress0 }}
              <span (click)="onDetails(activity, '5xx', '24hour')"
                >&#x21E7;</span
              >
            </td>
            <td class="ecount">
              {{ activity.count_500_3hour | suppress0 }}
              <span (click)="onDetails(activity, '5xx', '3hour')"
                >&#x21E7;</span
              >
            </td>
            <td class="ecount">
              {{ activity.count_500_15minute | suppress0 }}
              <span (click)="onDetails(activity, '5xx', '15minute')"
                >&#x21E7;</span
              >
            </td>
          </tr>
        }
      </tbody>
    </table>
  } @else {
    <mat-spinner />
  }
</div>

import {
  Component,
  Inject,
  ViewChild,
  ViewContainerRef,
  AfterViewInit,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogModule,
} from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation',
  standalone: true,
  imports: [MatDialogModule],
  templateUrl: './confirmation.component.html',
  styleUrl: './confirmation.component.scss',
})
export class ConfirmationComponent implements AfterViewInit {
  body?: any;
  context?: any;
  @ViewChild('body', { read: ViewContainerRef, static: false })
  bodyContainer!: ViewContainerRef;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.body = data.body;
    this.context = data.context;
  }

  ngAfterViewInit() {
    // TODO needs to be deleted or something; might matter someday
    this.bodyContainer.createEmbeddedView(this.body, this.context);
  }

  cancel() {
    this.dialogRef.close({ action: 'cancel' });
  }

  submit() {
    this.dialogRef.close({ action: 'confirmed' });
  }
}

<app-header />

<div class="m-2">
  <h1>Evaluations Last Month</h1>
  <table>
    <thead>
      <tr>
        <th scope="col">First Contact</th>
        <th scope="col">e-mail</th>
        <th scope="col">Tenant</th>
        <th scope="col">Time to Dialog</th>
        <th scope="col">Time in Dialog</th>
        <th scope="col">Claimed</th>
        <th scope="col">Pool Snapshot</th>
      </tr>
    </thead>
    <tbody>
      @for (eval of evaluations; track eval.id) {
        <tr
          [ngClass]="{
            'bg-slate-200': eval.tslug !== null,
            'text-gray-300': eval.tslug === null,
          }"
        >
          <td>
            {{ eval.first_contact | date: 'short' }}
          </td>
          <td>
            {{ eval.email_address }}
          </td>
          <td>
            @if (eval.tslug !== null) {
              <a [href]="'/deploy/' + eval.tslug">
                <span class="font-mono">{{ eval.tslug }}</span>
              </a>
              ({{ eval.status }})
            }
          </td>
          <td>
            {{ eval.time_to_dialog }}
          </td>
          <td>
            {{ eval.time_in_dialog }}
          </td>
          <td>
            @if (eval.claim_result !== null) {
              {{ eval.time_to_claimed }} ({{ eval.claim_result }})
            }
          </td>
          <td>
            {{ eval.snapshot_preclaim_info | snapshotPipe }}
          </td>
        </tr>
      }
    </tbody>
  </table>
</div>

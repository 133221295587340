<div class="titlebar">Invite User</div>
<div class="content">
  <div>
    <span
      >Company: <b>{{ data.target.company }}</b></span
    >
    <form>
      <div>
        <label>
          User E-mail:
          <input name="force" [(ngModel)]="data.email_addr" />
        </label>
      </div>
    </form>
  </div>
  <div class="footer">
    <button (click)="onInviteClick()">Invite</button>
    <button (click)="onCancel()">Cancel</button>
  </div>
</div>

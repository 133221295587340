<app-header />

<div class="flex flex-col gap-4 m-2">
  <p>
    The queries below can only be updated with direct file system access to the
    pbadmin server box. This is a security measure because they are plain SQL.
    See the source for these queries at
    <a href="https://github.com/integro212/precise/tree/main/admin/analytics"
      >github repo</a
    >
    but note that the pbadmin file system is the authoritative version and we
    may or may not keep up with incremental commits here.
  </p>

  <div class="flex gap-6">
    @if (
      {
        globalQueries: globalQueries$ | async,
        tenantQueries: tenantQueries$ | async,
      };
      as ctx
    ) {
      <div>
        <p>Query</p>
        <select
          name="query"
          [(ngModel)]="selectedQuery"
          (ngModelChange)="handleQueryChange($event)"
        >
          @if (selectedQuery === null) {
            <option [ngValue]="null" disabled selected hidden>
              <!-- it just works :) -->
              Select a query
            </option>
          }

          <optgroup label="Per Tenant">
            @for (query of ctx.tenantQueries; track query) {
              <option [ngValue]="{ basename: query.basename, group: 'tenant' }">
                {{ query.title }}
              </option>
            }
          </optgroup>
          <optgroup label="Global">
            @for (query of ctx.globalQueries; track query) {
              <option [ngValue]="{ basename: query.basename, group: 'global' }">
                {{ query.title }}
              </option>
            }
          </optgroup>
        </select>
      </div>
    }

    @if (tenants$ | async; as tenants) {
      @if (selectedQuery?.group === 'tenant') {
        <div>
          <p>Tenant</p>
          <select
            name="tenant"
            [(ngModel)]="selectedTenant"
            (ngModelChange)="handleTenantChange($event)"
          >
            <option value="__all__">**All**</option>
            @for (tenant of tenants; track tenant) {
              <option [value]="tenant.org_slug">
                {{ tenant.company }} ({{ tenant.org_slug }})
              </option>
            }
          </select>
        </div>
      }
    }
  </div>

  <div class="flex flex-col gap-2">
    @if (loading) {
      <mat-spinner />
    }
    @if (!loading && selectedQuery && currentQueryData) {
      <p>
        Query timestamp:
        {{ currentQueryData.meta['ts'] + 'Z' | date: 'medium' }}
      </p>

      <div class="flex gap-2">
        <button (click)="handleForceRefreshQueryResults()">
          Force refresh query results
        </button>

        <button>
          @if ({ global: selectedQuery.group === 'global' }; as ctx) {
            <a
              href="/api/analytics/{{ selectedQuery.group }}/{{
                selectedQuery.basename
              }}/run-xlsx{{ ctx.global ? '' : '?tslug=' }}{{
                ctx.global ? '' : selectedTenant
              }}"
            >
              Export XLSX
            </a>
          }
        </button>
      </div>

      <table>
        <thead>
          <tr>
            @for (column of currentQueryData.columns; track $index) {
              <th>{{ column[0] }}</th>
            }
          </tr>
        </thead>
        <tbody>
          @for (row of currentQueryData.rows; track $index) {
            <tr>
              @for (column of currentQueryData.columns; track $index) {
                <td>{{ row[column[0]] }}</td>
              }
            </tr>
          }
        </tbody>
      </table>
    }
  </div>
</div>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dataSummary', standalone: true })
export class DataSummaryPipe implements PipeTransform {
  transform(data: any) {
    if (data === undefined) {
      return 'unknown';
    }
    return `${data['week_distinct_users']} distinct users; ${data['week_count']} weekly sessions`;
  }
}

<div class="mat-typography w-full bg-primary flex place-content-center">
  <div class="internal-container">
    <p>
      As an internally approved administrator, use your production precise
      builder credentials for access to the admin tool.
    </p>
    <form (ngSubmit)="handleSubmit()" [formGroup]="loginForm" novalidate>
      <div class="flex flex-col gap-2.5">
        <mat-form-field>
          <input
            type="text"
            matInput
            formControlName="username"
            placeholder="Email"
          />

          @if (usernameCtrl.invalid) {
            <mat-error>
              <span>Username is required</span>
            </mat-error>
          }
        </mat-form-field>
        <br />

        <mat-form-field>
          <input
            matInput
            formControlName="password"
            placeholder="Password"
            type="password"
          />

          @if (passwordCtrl.hasError('required')) {
            <mat-error> Password is required </mat-error>
          }
        </mat-form-field>

        @if (loginError && !isLoading) {
          <mat-error data-cy="login-error">
            {{ loginError }}
          </mat-error>
        }
      </div>

      <button
        class="mt-4"
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="loginForm.invalid"
        data-cy="btn-login"
      >
        @if (isLoading) {
          <mat-spinner diameter="20" color="accent"></mat-spinner>
        }
        @if (!isLoading) {
          <span>Login</span>
        }
      </button>
    </form>
  </div>
</div>

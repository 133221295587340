<div class="titlebar">Create Tenant</div>
<div class="content">
  <div>
    <p>Enter Company Data</p>
    <form>
      <div>
        <span class="label">Company Name</span>
        <input name="company_name" [(ngModel)]="data.company_name" />
      </div>
      <div>
        <span class="label">Alias</span>
        <input name="alias" [(ngModel)]="data.alias" />
      </div>
      <div>
        <span class="label">Use Mode</span>
        <select name="usage_mode" [(ngModel)]="data.usage_mode">
          <option value="usage-customer">real customer</option>
          <option value="usage-example">example like</option>
          <option value="usage-uat">uat like</option>
        </select>
      </div>
      <div>
        <span class="label">Subscription Level</span>
        <select name="subs_level" [(ngModel)]="data.subs_level">
          <option value="regular">regular</option>
          <option value="limited">limited</option>
        </select>
      </div>
    </form>
  </div>
  <div class="footer">
    <button (click)="onCreateClick()">Create</button>
    <button (click)="onCancel()">Cancel</button>
  </div>
</div>

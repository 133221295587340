import { Component, OnInit, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'pbadmin';
  private http = inject(HttpClient);

  ngOnInit(): void {
    /*this.http.get('/api/hello').subscribe(data => {
      console.log(data);
    }, error => {
      console.error(error);
    });*/
  }
}

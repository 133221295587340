<div class="titlebar">Update Environment(s)</div>
<div class="content">
  <div>
    <span>Deployment Options</span>
    <form>
      @if (['prod-tenants', 'appdot'].includes(data.target)) {
        <div>
          <p>DANGER: This is a production build!!!</p>
          @if (data.target === 'prod-tenants') {
            <p>{{ data.targets.length }} environment(s)</p>
          }
        </div>
      }
      <div>
        <span class="label">Source</span>
        <input name="source" [(ngModel)]="data.source" />
      </div>
      <div>
        <span class="label">Branch</span>
        <select name="branch" [(ngModel)]="data.branch">
          @for (b of branches; track b) {
            <option [value]="b.branch">
              {{ b.label }}
            </option>
          }
        </select>
      </div>
      @if (data.target === 'uat') {
        <div>
          <label>
            <input
              name="force"
              [(ngModel)]="data.force_full_env"
              type="checkbox"
            />
            Force environment rebuild
          </label>
        </div>
      }
      @if (data.target === 'uat') {
        <div>
          <label>
            <input
              name="immediate"
              [(ngModel)]="data.immediate"
              type="checkbox"
            />
            Override manual deploy lockout
          </label>
        </div>
      }

      @if (data.target === 'uat') {
        <span>
          UAT Deployments from branches other than main will be in place for 1
          hour.
        </span>
      }

      @if (data.target === 'appdot') {
        <span>
          After this build "completes" you must do the final act manually in the
          prod-app VM.
        </span>
      }
    </form>
  </div>
  <div class="footer">
    <button (click)="onUpdateClick()">Update</button>
    <button (click)="onCancel()">Cancel</button>
  </div>
</div>

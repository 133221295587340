<app-header />

<h2>Properties of deployment {{ slug }}</h2>

<a href="/deployments">&lt; Deployments</a>

<h1>Containers</h1>

@if (containerLogs) {
  <div>
    <button (click)="onClearLogs()">&lt; All Containers</button>
    Showing logs for {{ containerName }}:
    <pre class="boxed">{{ containerBareLogs }}</pre>
  </div>
}

@if (envSummary?.containers && !containerLogs) {
  <ul>
    @for (container of envSummary.containers; track container) {
      <li>
        {{ container.Names }}
        <button (click)="onGetLogs(container.Names)">logs</button>
      </li>
    }
  </ul>
}

<h1>Raw Details</h1>

<div class="summaries">
  <pre class="boxed"
    >{{ dataSummary | json }}
    </pre
  >
  <pre class="boxed"
    >{{ envSummary | json }}
      </pre
  >
  <pre class="boxed"
    >{{ versionSummary | json }}
        </pre
  >
</div>

import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { HeaderComponent } from '../header/header.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { take, tap } from 'rxjs';

type SelectedQuery = {
  basename: string;
  group: 'global' | 'tenant';
};

type QueryMeta = {
  ts: string;
  name: string;
  tenant_head_data?: string[];
};

@Component({
  selector: 'app-analytics',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatProgressSpinnerModule,
    HeaderComponent,
  ],
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss'],
})
export class AnalyticsComponent {
  private http = inject(HttpClient);

  selectedTenant = '__all__';
  selectedQuery: SelectedQuery | null = null;

  globalQueries$ = this.http.get<any[]>('/api/analytics/global/list');
  tenantQueries$ = this.http.get<any[]>('/api/analytics/tenant/list');
  tenants$ = this.http.get<any[]>('/api/deployments');

  loading = false;

  currentQueryData: { rows: any[]; columns: any[]; meta: QueryMeta } | null =
    null;

  runQuery(forceRefresh = false) {
    this.loading = true;
    const url =
      this.selectedQuery!.group === 'global'
        ? `/api/analytics/global/${this.selectedQuery!.basename}/data?force_refresh=${forceRefresh}`
        : `/api/analytics/tenant/${this.selectedQuery!.basename}/data?tslug=${this.selectedTenant}&force_refresh=${forceRefresh}`;

    return this.http.get<any>(url).pipe(
      take(1),
      tap((payload) => {
        this.currentQueryData = payload;
        this.loading = false;
      })
    );
  }

  handleXLSXExport() {
    const tail =
      this.selectedQuery!.group == 'global'
        ? ''
        : `?tslug=${this.selectedTenant}`;
    return this.http.get(
      `/api/analytics/${this.selectedQuery!.group}/${this.selectedQuery!.basename}/run-xlsx${tail}`
    );
  }

  handleQueryChange(query: SelectedQuery) {
    this.runQuery().subscribe();
  }

  handleTenantChange(tenant: string) {
    if (this.selectedQuery!.group !== 'tenant') {
      return;
    }

    this.runQuery().subscribe();
  }

  handleForceRefreshQueryResults() {
    this.runQuery(true).subscribe();
  }
}

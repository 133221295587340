import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'versionSummary', standalone: true })
export class VersionSummaryPipe implements PipeTransform {
  transform(ver: any) {
    if (ver === undefined) {
      return 'unknown';
    }
    if (
      ver['desktop-client'] == ver['mobile-client'] &&
      ver['desktop-client'] == ver['backend']
    ) {
      return ver['desktop-client'];
    } else {
      return 'mixed';
    }
  }
}

import { Component, Inject, inject } from '@angular/core';

import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogModule,
} from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
  selector: 'app-create-tenant',
  imports: [
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
  ],
  templateUrl: './create-tenant.component.html',
  styleUrl: './create-tenant.component.scss',
})
export class CreateTenantComponent {
  private http = inject(HttpClient);

  dataDefaults = {
    company_name: '',
    alias: '',
    usage_mode: 'usage-customer',
    subs_level: 'regular',
  };

  constructor(
    public dialogRef: MatDialogRef<CreateTenantComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.data = { ...this.dataDefaults, ...data };
    console.log(this.data);
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onCreateClick(): void {
    const tenant: any = {
      company_name: this.data.company_name,
      alias: this.data.alias,
      subs_level: this.data.subs_level,
      usage_mode: this.data.usage_mode,
    };
    const body = JSON.stringify(tenant);
    this.http.post('/api/deployment/create', body).subscribe(
      (data) => {
        this.dialogRef.close({ created: true });
      },
      (error) => {
        console.error(error);
      }
    );
  }
}

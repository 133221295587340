import { Component, ChangeDetectorRef, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { interval, startWith, switchMap, map, Subject } from 'rxjs';
import { Suppress0Pipe } from './suppress0.pipe';
import { HeaderComponent } from '../header/header.component';

@Component({
  selector: 'app-activity',
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    Suppress0Pipe,
    HeaderComponent,
  ],
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss'],
})
export class ActivityComponent {
  private http = inject(HttpClient);
  private cdr = inject(ChangeDetectorRef);

  JSON: any = null;

  showDetails: 'none' | 'actlist' | 'logs' = 'none';
  showDetOrgSlug: any = null;

  activitySummary = interval(120 * 1000).pipe(
    startWith(0),
    switchMap(() => this.http.get<any[]>('/api/activity-summary'))
  );

  activityDetailMeta = new Subject<any>();
  activityDetails = this.activityDetailMeta.pipe(
    switchMap((meta) =>
      this.http.get<any[]>('/api/activity-detail', { params: meta })
    )
  );

  activityLogMeta = new Subject<any>();
  activityLogs = this.activityLogMeta.pipe(
    switchMap((meta) =>
      this.http.get<any>(`/api/deployment/${meta.org_slug}/docker-logs`, {
        params: meta,
      })
    ),
    map((data) => data['lines'].map((r: any) => r.text).join('\n'))
  );

  public constructor() {
    this.JSON = JSON;
  }

  onDetails(summ_row: any, errorType: string, lookback: string) {
    this.showDetails = 'actlist';
    this.showDetOrgSlug = summ_row.org_slug;
    this.cdr.detectChanges();
    this.activityDetailMeta.next({
      org_slug: summ_row.org_slug,
      act_name: summ_row.act_name,
      error_type: errorType,
      lookback,
    });
  }

  onLogs(actRow: any) {
    this.showDetails = 'logs';
    this.cdr.detectChanges();
    this.activityLogMeta.next({
      org_slug: this.showDetOrgSlug,
      container: 'precise-web-1',
      timestamp: actRow.request_timestamp,
    });
  }

  onClearDetails() {
    this.showDetails = 'none';
  }
}

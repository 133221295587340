import {
  Component,
  OnInit,
  inject,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { HttpClient } from '@angular/common/http';
import { VersionSummaryPipe } from './version.pipe';
import { DataSummaryPipe } from './data-summary.pipe';
import { EnvironmentSummaryPipe } from './environment-summary.pipe';
import { UpdateToolComponent } from '../update-tool/update-tool.component';
import { InviteToolComponent } from '../invite-tool/invite-tool.component';
import { CreateTenantComponent } from '../create-tenant/create-tenant.component';
import { ConfirmationComponent } from '../confirmation/confirmation.component';
import { interval, pipe, filter, delay, switchMap, tap } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { HeaderComponent } from '../header/header.component';

@Component({
  selector: 'app-deployments',
  standalone: true,
  imports: [
    CommonModule,
    VersionSummaryPipe,
    DataSummaryPipe,
    EnvironmentSummaryPipe,
    HeaderComponent,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
  ],
  templateUrl: './deployments.component.html',
  styleUrls: ['./deployments.component.scss'],
})
export class DeploymentsComponent implements OnInit {
  private http = inject(HttpClient);
  private dialog = inject(MatDialog);

  @ViewChild('powerUpConfirm') public powerUpConfirm!: TemplateRef<any>;
  @ViewChild('powerDownConfirm') public powerDownConfirm!: TemplateRef<any>;
  @ViewChild('decommissionConfirm')
  public decommissionConfirm!: TemplateRef<any>;

  companies: any[] = [];
  bqueue: any[] = [];

  refreshQueues = interval(15000).pipe(takeUntilDestroyed());

  ngOnInit(): void {
    this.refreshCompanyList();

    this.refreshQueues.subscribe(() => {
      this.updateQueue();
    });
    this.updateQueue();
  }

  refreshCompanyList() {
    this.http.get('/api/deployments').subscribe(
      (data) => {
        this.companies = data as any[];
      },
      (error) => {
        console.error(error);
      }
    );
  }

  updateQueue() {
    this.http.get('/api/deploy/queue').subscribe(
      (data: any) => {
        const q = data.data;
        q.forEach((r: any) => {
          r.ingest_ts =
            ((new Date().getTime() / 1000 - r.ingest_ts) / 60).toFixed(1) +
            ' minutes';
          r.last_status_ts =
            ((new Date().getTime() / 1000 - r.last_status_ts) / 60).toFixed(1) +
            ' minutes';
        });
        this.bqueue = q;
      },
      (error) => {
        console.error(error);
      }
    );
  }

  updateCompanyValue(company: any, key: string, url: string) {
    this.http.get(url).subscribe(
      (data) => {
        company[key] = data;
      },
      (error) => {
        console.error(error);
      }
    );
  }

  onRefreshVersions() {
    this.companies
      .filter((c) => c.status === 'live')
      .forEach((c) =>
        this.updateCompanyValue(
          c,
          'versions',
          `/api/deployment/${c.org_slug}/version-stats`
        )
      );
  }

  onRefreshData() {
    this.companies
      .filter((c) => c.status === 'live')
      .filter((c) => c.org_slug !== 'uat')
      .forEach((c) =>
        this.updateCompanyValue(
          c,
          'dataStats',
          `/api/deployment/${c.org_slug}/data-stats`
        )
      );
  }

  onRefreshEnvironment() {
    this.companies
      .filter((c) => c.status === 'live')
      .forEach((c) =>
        this.updateCompanyValue(
          c,
          'environmentStats',
          `/api/deployment/${c.org_slug}/env-stats`
        )
      );
  }

  onInviteUser(comp: any) {
    const dialogRef = this.dialog.open(InviteToolComponent, {
      width: '350px',
      data: { target: comp },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.updateQueue();
    });
  }

  onUpdateUat() {
    const dialogRef = this.dialog.open(UpdateToolComponent, {
      width: '350px',
      data: { target: 'uat', source: 'admin tool' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.updateQueue();
    });
  }

  onPowerDown(comp: any) {
    this.dialog
      .open(ConfirmationComponent, {
        width: '350px',
        data: { body: this.powerDownConfirm, context: { tenant: comp } },
      })
      .afterClosed()
      .pipe(
        filter((result) => result.action === 'confirmed'),
        switchMap((result) =>
          this.http.put(`/api/deployment/${comp.org_slug}/power-down`, null)
        ),
        delay(2000),
        tap(() => this.refreshCompanyList())
      )
      .subscribe();
  }

  onDecommission(comp: any) {
    this.dialog
      .open(ConfirmationComponent, {
        width: '350px',
        data: { body: this.decommissionConfirm, context: { tenant: comp } },
      })
      .afterClosed()
      .pipe(
        filter((result) => result.action === 'confirmed'),
        switchMap((result) =>
          this.http.put(`/api/deployment/${comp.org_slug}/power-down`, {
            decommission: true,
          })
        ),
        delay(2000),
        tap(() => this.refreshCompanyList())
      )
      .subscribe();
  }

  onPowerUp(comp: any) {
    this.dialog
      .open(ConfirmationComponent, {
        width: '350px',
        data: { body: this.powerUpConfirm, context: { tenant: comp } },
      })
      .afterClosed()
      .pipe(
        filter((result) => result.action === 'confirmed'),
        switchMap((result) =>
          this.http.put(`/api/deployment/${comp.org_slug}/power-up`, null)
        ),
        delay(1.5 * 60 * 1000),
        tap(() => this.refreshCompanyList())
      )
      .subscribe();
  }

  onUpdateProd() {
    let targets = this.companies.filter((c) => !c.org_slug.startsWith('t0000'));

    if (targets.some((c) => c.selected)) {
      targets = targets.filter((c) => c.selected);
    }

    const target_slugs = targets.map((c) => c.org_slug);

    const dialogRef = this.dialog.open(UpdateToolComponent, {
      width: '350px',
      data: {
        target: 'prod-tenants',
        targets: target_slugs,
        source: 'prod build',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.updateQueue();
    });
  }

  onUpdateAppdot() {
    const dialogRef = this.dialog.open(UpdateToolComponent, {
      width: '350px',
      data: { target: 'appdot', source: 'appdot build' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.updateQueue();
    });
  }

  onCreateTenant() {
    const dialogRef = this.dialog.open(CreateTenantComponent, {
      width: '350px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.refreshCompanyList();
    });
  }
}
